body {
    background-color: #f5f5f5;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0;
    padding: 0;
    font-family: 'JetBrains Sans', sans-serif;
}

h1 {
    margin-bottom: 20px;
    color: #333;
}

.controls {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 1em;
    margin-top: 1em;
    margin-bottom: 1em;
    width: 100%;
    justify-content: center;
}

button {
    cursor: pointer;
    background-color: #4080ff;
    color: #fff;
    font-weight: bold;
    border: none;
    border-radius: 6px;
    box-shadow: 1px 1px 6px rgba(0, 0, 0, 0.1);
    padding: 10px 15px;
    transition: 0.3s background-color;
    font-family: 'JetBrains Sans', sans-serif;
}

button:hover {
    background-color: #386ad4;
}

button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
}

select {
    background-color: #fff;
    border: none;
    border-radius: 6px;
    box-shadow: 1px 1px 6px rgba(0, 0, 0, 0.1);
    padding: 8px 10px;
    font-family: 'JetBrains Sans', sans-serif;
    font-weight: bold;
}

.spinner {
    display: none;
    position: absolute;
    top: calc(50% - 25px);
    left: calc(50% - 25px);
    width: 40px;
    height: 40px;
    border: 5px solid #f5f5f5;
    border-top-color: #4080ff;
    border-radius: 50%;
    animation: spin 0.8s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.error-message {
    display: none;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 100%;
    height: auto;
    padding: 1em;
    background-color: #ff5555;
    color: #fff;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.15);
    border-radius: 6px;
    text-align: center;
    font-family: 'JetBrains Mono', monospace;
    font-weight: bold;
}

.image-container {
    position: relative;
    width: 100%;
    aspect-ratio: 16/9; /* 16:9 aspect ratio */
    max-width: calc(100vh - 10rem); /* Keep width within the vertical limits */
    background-color: #ddd;
    border-radius: 6px;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.15);
    background-position: center center;
    background-repeat: no-repeat;
    margin-bottom: 1em;
}

img {
    max-width: 100%;
    max-height: calc(100vh - 10rem);
    height: auto;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.15);
    display: block;
    object-fit: cover;
    border-radius: 6px;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
}

.outer-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    width: 100%;
    padding: 1rem;
    box-sizing: border-box;
}

@font-face {
    font-family: 'JetBrains Sans';
    src: url('JetBrainsSans-Regular.woff2') format('woff2');
    font-weight: normal;
}

@font-face {
    font-family: 'JetBrains Sans';
    src: url('JetBrainsSans-SemiBold.woff2') format('woff2');
    font-weight: bold;
}

.main-title {
    font-size: 24px;
}

.control-button {
    font-size: 16px;
}

.style-select {
    font-size: 16px;
}

@media (min-width: 992px) {
    .main-title {
        font-size: 36px;
    }

    .control-button {
        font-size: 24px;
    }

    .style-select {
        font-size: 24px;
    }
}

@media (min-width: 1440px) {
    .main-title {
        font-size: 48px;
    }

    .control-button {
        font-size: 32px;
    }

    .style-select {
        font-size: 32px;
    }
}

.control-label {
    font-size: 16px;
    font-weight: bold;
}

@media (min-width: 992px) {
    .control-label {
        font-size: 24px;
    }
}

@media (min-width: 1440px) {
    .control-label {
        font-size: 32px;
    }
}

.error-message {
    font-size: 16px;
}

@media (min-width: 992px) {
    .error-message {
        font-size: 24px;
    }
}

@media (min-width: 1440px) {
    .error-message {
        font-size: 32px;
    }
}

.footer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
    margin-top: 1rem;
}

.footer-link {
    text-decoration: none;
    color: #333;
    font-weight: bold;
    transition: 0.3s color;
}

.footer-link:hover {
    color: #4080ff;
}

.footer-link:not(:last-child) {
    margin-right: 1rem;
}

select:hover, select:focus {
    background-color: #e5e5e5;
    box-shadow: 1px 1px 6px rgba(0, 0, 0, 0.15), 0 0 0 2px rgba(0, 0, 255, 0.1);
    outline: none;
}

.blurred {
    filter: blur(14px);
}